import * as React from "react";
import type { HeadFC } from "gatsby";

import "../components/layout.css";

const pageStyles = {
  color: "#232129",
  height: "100vh",
  fontFamily: "-apple-system, Roboto, sans-serif, serif",
};

const IndexPage = () => {
  return (
    <main style={pageStyles}>
      <iframe
        className="airtable-embed"
        src="https://airtable.com/embed/shr1yAB7BzSIOfMwk?backgroundColor=green"
        frameBorder="0"
        onWheel={(e: any) => e.preventDefault()}
        // Make the iframe as big as its content.
        style={{
          width: "100%",
          height: "100vh",
          border: "none",
          background: "transparent",
        }}
      ></iframe>
    </main>
  );
};

export default IndexPage;

export const Head: HeadFC = () => <title>Cruz Democrats</title>;
